<template>
  <div>
    <el-drawer v-model="isOpen" title="I am the title" :with-header="false" size="576px">
      <el-input v-model="searchVal" prefix-icon="el-icon-search" clearable />
      <el-table :data="list" stripe :max-height="clientHeight - 100">
        <el-table-column
          property="id"
          label="ID"
          width="50px"
          align="center"
        ></el-table-column>
        <el-table-column
          property="title"
          label="文章标题"
          width="170px"
          align="center"
        ></el-table-column>
        <el-table-column
          property="category.name"
          label="分类"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="openDialog(2, scope.row)"
              >修改</el-button
            >
            <el-button
              :type="scope.row.is_del ? 'warning' : 'danger'"
              size="small"
              @click="remove(scope.row)"
              >{{ scope.row.is_del ? '软恢复' : '软删除' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="add">
        <el-button type="primary" @click="openDialog(1)">添加</el-button>
      </div>
    </el-drawer>
    <el-dialog
      v-model="dialog"
      :title="submitType === 1 ? '添加' : '修改'"
      center
      width="30%"
      @close="closeDialog"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="文章标题" prop="title">
          <el-input v-model="form.title" placeholder="请选择文章标题"></el-input>
        </el-form-item>
        <el-form-item label="文章分类" prop="categoryId">
          <el-select
            v-model="form.categoryId"
            placeholder="请选择文章分类"
            style="width: 100%;"
          >
            <el-option
              v-for="(item, index) in categoryListCom"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章标志">
          <el-select
            v-model="form.tag"
            placeholder="请选择文章标志"
            style="width: 100%;"
          >
            <el-option
              v-for="(item, index) in tagList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, watch, reactive } from 'vue'
import {
  getArticleList,
  createArticle,
  updateArticle,
  removeArticle,
  getArticleDetail
} from '@/api/article'
// import { getCategoryList } from '@/api/category'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    drawer: {
      type: Boolean
    },
    drawerType: {
      type: Number
    },
    categoryListCom: {
      type: Array
    }
  },
  setup (props, { emit }) {
    const isOpen = ref(false)
    const list = ref([])
    // const categoryList = ref([])
    // 当前浏览器高度
    const clientHeight = ref(document.body.clientHeight)
    let listCopy = null
    getArticleList({ include: 'is_del' }).then(res => {
      if (res.data.data instanceof Array && res.data.data.length > 0) {
        list.value = res.data.data
        listCopy = [...list.value]
      }
    })
    // getCategoryList().then(res => {
    //   if (res.data instanceof Array && res.data.length > 0) {
    //     categoryList.value = res.data
    //   }
    // })
    const searchVal = ref('')
    watch(searchVal, val => {
      if (val.trim() !== '') {
        list.value = listCopy.filter(item => {
          return item.title
            .toLocaleLowerCase()
            .includes(val.toLocaleLowerCase())
        })
      } else {
        list.value = listCopy
      }
    })
    watch(
      () => props.drawer,
      val => {
        if (isOpen.value !== val) isOpen.value = val
      }
    )
    watch(isOpen, val => {
      emit('update:drawer', val)
    })
    const ruleForm = ref(null)
    const dialog = ref(false)
    const tagList = ref([{ label: '使用描点', value: 'anchor' }])
    const form = reactive({
      title: '',
      author: '陈钧',
      categoryId: '',
      tag: '',
      introduction: '',
      cover: '',
      content: ''
    })
    const rules = reactive({
      title: [{ required: true }],
      categoryId: [{ required: true }]
    })
    const submitType = ref(null)
    const submit = () => {
      ruleForm.value.validate(val => {
        if (!val) return
        if (submitType.value === 1) {
          if (form.tag !== 'anchor') form.content = '# ' + form.title
          createArticle(form).then(res => {
            ElMessage.success(res.message)
            getArticleList({ include: 'is_del' }).then(res => {
              if (res.data.data instanceof Array && res.data.data.length > 0) {
                list.value = res.data.data
                listCopy = [...list.value]
              }
            })
            emit('updateList')
            dialog.value = false
          })
        } else {
          updateArticle(form).then(res => {
            ElMessage.success(res.message)
            getArticleList({ include: 'is_del' }).then(res => {
              if (res.data.data instanceof Array && res.data.data.length > 0) {
                list.value = res.data.data
                listCopy = [...list.value]
              }
            })
            emit('updateList')
            dialog.value = false
          })
        }
      })
    }
    const remove = row => {
      removeArticle({ id: row.id, is_del: row.is_del ? 0 : 1 }).then(res => {
        ElMessage.success(res.message)
        getArticleList({ include: 'is_del' }).then(res => {
          if (res.data.data instanceof Array && res.data.data.length > 0) {
            list.value = res.data.data
            listCopy = [...list.value]
          }
        })
        emit('updateList')
      })
    }
    const openDialog = async (type, row) => {
      submitType.value = type
      if (type === 1) {
        delete form.id
      } else {
        form.id = row.id
        form.title = row.title
        form.categoryId = row.categoryId
        const res = await getArticleDetail({ id: row.id })
        form.content = res.data.content
      }
      dialog.value = true
    }
    const closeDialog = () => {
      // ruleForm.value.resetFields()
      form.title = ''
      form.categoryId = ''
      form.content = ''
    }
    return {
      isOpen,
      list,
      clientHeight,
      ruleForm,
      dialog,
      form,
      rules,
      submit,
      closeDialog,
      openDialog,
      submitType,
      remove,
      //   categoryList,
      searchVal,
      tagList
    }
  }
})
</script>

<style scoped lang="scss">
.add {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .el-button {
    width: 300px;
  }
}
.el-input {
  padding: 0 2px;
}
</style>
