import _http from '@/utils/request'

// 登录
export function login (data) {
  return _http({
    url: '/user/login',
    method: 'post',
    data
  })
}

// 注册
export function register (data) {
  return _http({
    url: '/user/register',
    method: 'post',
    data
  })
}

// 删除
export function deleteUser (data) {
  return _http({
    url: '/user/delete/' + data.id,
    method: 'delete'
  })
}

export function info () {
  return _http({
    url: '/user/info'
  })
}

export function userList (params) {
  return _http({
    url: '/user/list',
    params
  })
}

// email: "chenjun916216@163.com"
// id: 1
// token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImNoZW5qdW4iLCJpZCI6MSwiZW1haWwiOiJjaGVuanVuOTE2MjE2QDE2My5jb20iLCJpYXQiOjE2MzUwMDQyMzQsImV4cCI6MTYzNTAwNzgzNH0.gxGuBct3qMbmfbkKonejMaYBY1wvUS41-N90yscGU9w"
// username: "chenjun"
