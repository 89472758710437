<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    background-color="#545c64"
    text-color="#b6b4ae"
    active-text-color="#c4dfb8"
    @select="handleSelect"
    :ellipsis="false"
  >
    <template v-for="(item, index) in categoryList" :key="index">
      <MenuItem :item="item" @activeSubMenu="activeSubMenu" />
    </template>
  </el-menu>
</template>

<script>
import { defineComponent, ref } from 'vue'
import MenuItem from '@/components/menuItem'
export default defineComponent({
  components: { MenuItem },
  props: {
    categoryList: {
      type: Array
    }
  },
  setup (props, { emit }) {
    const activeIndex = ref('0')
    const handleSelect = key => {
      emit('handleSelect', Number(key))
    }
    const activeSubMenu = key => {
      emit('handleSelect', Number(key))
    }
    return {
      activeIndex,
      handleSelect,
      activeSubMenu
    }
  }
})
</script>

<style scoped lang="scss">
:deep.el-menu {
  flex-wrap: wrap;
  .el-menu-item,
  .el-sub-menu .el-sub-menu__title {
    border-bottom: unset;
    height: 70px;
    line-height: 70px;
    font-weight: 777;
    font-size: 20px;
  }
  .el-menu-item.is-active,
  .el-sub-menu.is-active .el-sub-menu__title {
    border-bottom: unset;
    background-color: #434a50 !important;
  }
  .el-sub-menu__icon-arrow {
    display: none;
  }
  .el-sub-menu {
    .el-sub-menu__title {
      &:hover {
        background-color: #434a50 !important;
      }
    }
  }
}
</style>
