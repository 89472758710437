<template>
  <div>
    <el-drawer
      v-model="isOpen"
      title="I am the title"
      :with-header="false"
      size="576px"
    >
      <el-table :data="list" stripe :max-height="clientHeight - 100">
        <el-table-column
          property="id"
          label="ID"
          width="50px"
          align="center"
        ></el-table-column>
        <el-table-column
          property="name"
          label="分类名称"
          width="170px"
          align="center"
        ></el-table-column>
        <el-table-column
          property="parent_id"
          label="父分类"
          align="center"
        ></el-table-column>
        <el-table-column
          property="z_index"
          label="层级"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="150px" align="center">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="openDialog(2, scope.row)"
              >修改</el-button
            >
            <el-button type="danger" size="small" @click="remove(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="add">
        <el-button type="primary" @click="openDialog(1)">添加</el-button>
      </div>
    </el-drawer>
    <el-dialog
      v-model="dialog"
      :title="submitType === 1 ? '添加' : '修改'"
      center
      width="30%"
      @close="closeDialog"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="父级分类" prop="parent_id">
          <el-select
            v-model="form.parent_id"
            placeholder="Select"
            style="width: 100%;"
          >
            <el-option
              v-for="(item, index) in list"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层级" prop="z_index">
          <el-input v-model="form.z_index"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, watch, reactive } from 'vue'
import {
  getCategoryList,
  createCategory,
  updateCategory,
  deleteCategory
} from '@/api/category'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    drawer: {
      type: Boolean
    },
    drawerType: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const isOpen = ref(false)
    const list = ref([])
    // 当前浏览器高度
    const clientHeight = ref(document.body.clientHeight)
    getCategoryList().then(res => {
      if (res.data instanceof Array && res.data.length > 0) {
        list.value = res.data
        emit('updateList', list.value)
      }
    })
    watch(
      () => props.drawer,
      val => {
        if (isOpen.value !== val) isOpen.value = val
      }
    )
    watch(isOpen, val => {
      emit('update:drawer', val)
    })
    const ruleForm = ref(null)
    const dialog = ref(false)
    const form = reactive({ name: '', icon: '', parent_id: 0, z_index: 10 })
    const rules = reactive({
      name: [{ required: true }],
      icon: [{ required: true }],
      parent_id: [{ required: true }],
      z_index: [{ required: true }]
    })
    const submitType = ref(null)
    const submit = () => {
      ruleForm.value.validate(val => {
        if (!val) return
        if (submitType.value === 1) {
          createCategory(form).then(res => {
            ElMessage.success(res.message)
            getCategoryList().then(res => {
              if (res.data instanceof Array && res.data.length > 0) {
                list.value = res.data
                emit('updateList', list.value)
                dialog.value = false
              }
            })
          })
        } else {
          updateCategory(form).then(res => {
            ElMessage.success(res.message)
            getCategoryList().then(res => {
              if (res.data instanceof Array && res.data.length > 0) {
                list.value = res.data
                emit('updateList', list.value)
                dialog.value = false
              }
            })
          })
        }
      })
    }
    const remove = id => {
      const flag = list.value.find(item => id === item.parent_id)
      if (flag) {
        ElMessage.error('存在子分类：' + flag.name + '，不能删除')
      } else {
        deleteCategory({ id }).then(res => {
          ElMessage.success(res.message)
          getCategoryList().then(res => {
            if (res.data instanceof Array && res.data.length > 0) {
              list.value = res.data
              emit('updateList', list.value)
            }
          })
        })
      }
    }
    const openDialog = (type, row) => {
      dialog.value = true
      submitType.value = type
      if (type === 1) {
        delete form.id
      } else {
        form.id = row.id
        form.name = row.name
        form.parent_id = row.parent_id
        form.z_index = row.z_index
      }
    }
    const closeDialog = () => {
      // ruleForm.value.resetFields()
      form.name = ''
      form.parent_id = 0
      form.z_index = 10
    }
    return {
      isOpen,
      list,
      clientHeight,
      ruleForm,
      dialog,
      form,
      rules,
      submit,
      closeDialog,
      openDialog,
      submitType,
      remove
    }
  }
})
</script>

<style scoped lang="scss">
.add {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .el-button {
    width: 300px;
  }
}
</style>
