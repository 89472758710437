import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import store from '@/store'
// import router from '@/router'

const _fetch = axios.create({
  baseURL: 'http://speedometers.cn/api/v1',
  timeout: 30000
})

let loading = null

_fetch.interceptors.request.use(
  function (config) {
    loading = ElLoading.service({ lock: true })
    if (store.getters.token) {
      config.headers.Authorization = 'Bearer ' + store.getters.token
    }
    return config
  },
  function (err) {
    loading.close()
    return Promise.reject(err)
  }
)

_fetch.interceptors.response.use(
  function (response) {
    loading.close()
    return response.data
  },
  function (error) {
    loading.close()
    if (error.response && error.response.status === 401) {
      store.commit('user/setToken', null)
    }
    ElMessage.error(
      error.response.data.message || error.response.data.err.message
    )
    return Promise.reject(error)
  }
)

export default _fetch
