import _http from '@/utils/request'

export function getOssList (params) {
  return _http({
    url: '/oss/list',
    params
  })
}

export function putOssStream (data) {
  return _http({
    url: '/oss/putStream',
    method: 'post',
    data
  })
}

export function removeOSS (params) {
  return _http({
    url: '/oss/deleteFile',
    method: 'delete',
    params
  })
}
