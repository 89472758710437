import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user'
import createPersistedstate from 'vuex-persistedstate'

export default createStore({
  modules: {
    user
  },
  getters,
  plugins: [
    createPersistedstate({
      key: 'user-store',
      paths: ['user']
    })
  ]
})
