import _http from '@/utils/request'

// 获取分类列表
export function getCategoryList (params) {
  return _http({
    url: '/category/list',
    params
  })
}

// 查询分类ID下的所有文章列表
export function getCategoryArticleList (params) {
  return _http({
    url: '/category/article/' + params.id,
    params
  })
}

// 获取分类详情信息
export function getCategoryInfo (params) {
  return _http({
    url: '/category/detail/' + params.id
  })
}

// 新建分类
export function createCategory (data) {
  return _http({
    url: '/category/create',
    method: 'post',
    data
  })
}

// 删除分类
export function deleteCategory (data) {
  return _http({
    url: '/category/delete/' + data.id,
    method: 'delete'
  })
}

// 更新分类
export function updateCategory (data) {
  return _http({
    url: '/category/update/' + data.id,
    method: 'put',
    data
  })
}
