<template>
  <div>
    <el-drawer
      v-model="isOpen"
      title="I am the title"
      :with-header="false"
      size="576px"
    >
      <div class="files-head">
        <el-select v-model="currentPath" clearable placeholder="请选择添加分类">
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.title"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-upload
          action="https://speedometers.cn/api/v1/oss/putStream"
          :headers="{ Authorization: 'Bearer ' + $store.getters.token }"
          :data="{ category: 'files/' + currentPath }"
          :before-upload="handleBeforeUpload"
          :on-success="handleSuccess"
          :on-error="handleError"
          :show-file-list="false"
          multiple
        >
          <el-button type="primary">添加文件</el-button>
        </el-upload>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="item in options"
          :key="item.name"
          :title="item.title"
          :name="item.name"
        >
          <el-table :data="item.tableData" size="small">
            <el-table-column prop="name">
              <template #header></template>
            </el-table-column>
            <el-table-column prop="date" width="100px">
              <template #header></template>
            </el-table-column>
            <el-table-column width="160px">
              <template #header></template>
              <template #default="scope">
                <div class="self-func">
                  <a
                    class="download"
                    :href="scope.row.url"
                    :download="scope.row.name"
                    >下载</a
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="removeFile(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>
  </div>
</template>

<script>
import { defineComponent, ref, watch, reactive } from 'vue'
import { getOssList, removeOSS } from '@/api/upload'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    drawer: {
      type: Boolean
    },
    drawerType: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const isOpen = ref(false)
    watch(
      () => props.drawer,
      val => {
        if (isOpen.value !== val) isOpen.value = val
      }
    )
    watch(isOpen, val => {
      emit('update:drawer', val)
    })
    const handleBeforeUpload = file => {
      if (currentPath.value === '') {
        ElMessage.error('请先选择分类')
        return false
      }
      const isLt30M = file.size / 1024 / 1024 < 100
      if (!isLt30M) {
        ElMessage.error('文件大小不能超过100MB!')
      }
      return isLt30M
    }
    const handleSuccess = (response, file) => {
      if (response.code === 200) {
        getFileList()
        ElMessage.success(file.name + ' 上传成功')
      }
    }
    const handleError = (e, file) => {
      ElMessage.error(file.name + ' 上传失败')
    }
    const getFileList = () => {
      options.forEach(item => {
        item.tableData = []
      })
      getOssList({ category: 'files' }).then(res => {
        res.data.forEach(item => {
          item.name = item.name.replace('files/', '')
          const category = item.name.split('/')[0]
          const fileNmae = item.name.split('/')[1]
          options
            .find(val => val.name === category)
            .tableData.push({
              name: fileNmae,
              date: item.lastModified.split('T')[0],
              url: item.url.replace('http', 'https'),
              category
            })
        })
      })
    }
    const removeFile = row => {
      removeOSS({ name: `files/${row.category}/${row.name}` }).then(res => {
        getFileList()
        ElMessage.success(res.message)
      })
    }
    // 添加文件功能
    const options = reactive([
      { title: '文件', name: 'file', tableData: [] },
      { title: '图片', name: 'image', tableData: [] },
      { title: '代码', name: 'code', tableData: [] },
      { title: '工具', name: 'utils', tableData: [] },
      { title: '其它', name: 'other', tableData: [] }
    ])
    const currentPath = ref('')
    const activeNames = ref([])

    getFileList()
    return {
      isOpen,
      handleBeforeUpload,
      handleSuccess,
      handleError,
      getFileList,
      removeFile,
      activeNames,
      currentPath,
      options
    }
  }
})
</script>

<style scoped lang="scss">
:deep div {
  .el-drawer__body {
    padding: 10px;
    overflow-y: auto;
  }
}
.files-head {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
:deep.el-table {
  --el-table-border-color: unset;
  .el-table__header-wrapper {
    display: none;
  }
}
:deep.el-collapse-item {
  .el-collapse-item__header {
    font-size: 18px;
    font-weight: 700;
    &.is-active {
      color: #409eff;
    }
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
}
.self-func {
  display: flex;
  justify-content: right;
  .download {
    width: 56px;
    height: 28px;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    line-height: 28px;
    text-decoration: none;
    color: #fff;
    margin-right: 10px;
  }
}
</style>
