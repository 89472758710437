<template>
  <!-- 我要一步一步往上爬 -->
  <el-container>
    <el-header>
      <div class="barnner" v-if="!$store.getters.isMobile">
        <div class="left">
          <img
            src="@/assets/20220718225238.jpg"
            @click="getlogin"
            :style="$store.getters.token && 'box-shadow: 2px 2px 5px #000;'"
          />
          <a href="javascript:void(0);">Speedometers</a>
        </div>
        <p>涅盘重生</p>
        <div class="rigth">
          <el-button type="info" @click="drawerOpen(1)">文件管理</el-button>
          <el-button type="info" @click="drawerOpen(2)">分类管理</el-button>
          <el-button
            type="info"
            style="margin-right: 20px;"
            @click="drawerOpen(3)"
            >文章管理</el-button
          >
          <el-input
            v-model="searchVal"
            prefix-icon="el-icon-search"
            clearable
          />
        </div>
      </div>
      <Menu :categoryList="categoryList" @handleSelect="handleSelect" />
    </el-header>
    <el-main>
      <div class="main">
        <el-row :gutter="20">
          <el-col
            :xs="12"
            :sm="8"
            :lg="4"
            v-for="(item, index) in articleList"
            :key="index"
          >
            <div class="grid-content" @click="jumpDetail(item.id)">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="date">
                <span>{{ item.category.name }}</span
                ><span>{{ item.updatedAt }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="bottom">
          <div class="footer">
            <p>©Speedometers&nbsp;&nbsp;享受学习的快乐</p>
            <div class="links">
              <a href="javascript:void(0);">关于我们</a>
              <span style="margin: 0 6px;">|</span>
              <a href="javascript:void(0);">联系我们</a>
              <span style="margin: 0 6px;">|</span>
              <a href="javascript:void(0);">招聘人才</a>
              <span style="margin: 0 6px;">|</span>
              <a href="javascript:void(0);">友情链接</a>
            </div>
            <p>CopyRight © 2021 Speedometers All Rights Reserved</p>
            <p>电话：0750-****888&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href="https://www.icplist.com/icp/info/%E7%B2%A4ICP%E5%A4%872020131517%E5%8F%B7" style="color: rgb(102, 102, 102);">粤ICP备2020131517号</a></p>
            <div style="width:300px;margin:0 auto; padding:5px 0;">
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060602001861"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
                ><img src="@/assets/beian.png" style="float:left;" />
                <p
                  style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#666;"
                >
                  粤公网安备 44060602001861号
                </p></a
              >
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
  <el-dialog v-model="dialogLogin" title="登录" center width="30%">
    <el-form :model="formLogin" :rules="rules" ref="ruleForm">
      <el-form-item label="账号" prop="username">
        <el-input v-model="formLogin.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="formLogin.password"
          show-password
          @keyup.enter="submitLogin"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogLogin = false">取消</el-button>
        <el-button type="primary" @click="submitLogin">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <template v-if="$store.getters.token">
    <DrawerFiles v-model:drawer="drawerFiles" :drawerType="drawerType" />
    <DrawerCategory
      v-model:drawer="drawerCategory"
      :drawerType="drawerType"
      @updateList="updateList"
    />
    <DrawerArticle
      v-model:drawer="drawerArticle"
      :categoryListCom="categoryListCom"
      :drawerType="drawerType"
      @updateList="updateList"
    />
  </template>
</template>

<script>
import { defineComponent, ref, reactive, watch } from 'vue'
import Menu from '@/components/menu'
import DrawerFiles from './components/drawerFiles'
import DrawerCategory from './components/drawerCategory'
import DrawerArticle from './components/drawerArticle'
import { getArticleList } from '@/api/article'
import { getCategoryList } from '@/api/category'
import { useStore } from 'vuex'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: { Menu, DrawerCategory, DrawerArticle, DrawerFiles },
  setup () {
    const store = useStore()
    const router = useRouter()
    if (!store.getters.isMobile) {
      document.documentElement.style.height = '100%'
      document.body.style.height = '100%'
      document.querySelector('#app').style.height = '100%'
    }
    // 获取分类列表数据
    const categoryList = ref([])
    getCategoryList({ include: 'tree' }).then(res => {
      if (res.data instanceof Array && res.data.length > 0) {
        res.data.unshift({ id: 0, name: '全部' })
        categoryList.value = res.data
      }
    })
    // 获取文章列表数据
    const articleList = ref([])
    let articleListCopy = null
    getArticleList().then(res => {
      if (res.data.data instanceof Array && res.data.data.length > 0) {
        articleList.value = res.data.data
        articleListCopy = [...articleList.value]
      }
    })
    // 分类获取列表
    const handleSelect = key => {
      if (!articleListCopy) return
      if (key === 0) {
        articleList.value = articleListCopy
      } else {
        articleList.value = articleListCopy.filter(item => {
          return item.categoryId === key
        })
      }
    }
    const searchVal = ref('')
    watch(searchVal, val => {
      if (val.trim() !== '') {
        articleList.value = articleListCopy.filter(item => {
          return item.title
            .toLocaleLowerCase()
            .includes(val.toLocaleLowerCase())
        })
      } else {
        articleList.value = articleListCopy
      }
    })
    const jumpDetail = id => {
      window.open(router.resolve({ path: '/detail/' + id }).href, '_blank')
    }
    // 登录逻辑
    const ruleForm = ref(null)
    const dialogLogin = ref(false)
    const formLogin = reactive({ username: '', password: '' })
    const rules = reactive({
      username: [{ required: true }],
      password: [{ required: true }]
    })
    const getlogin = () => {
      if (store.getters.token) {
        ElMessageBox.confirm('已登录，是否刷新Token?', 'Tip', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.commit('user/setToken', null)
          dialogLogin.value = true
        })
        return
      }
      dialogLogin.value = true
    }
    const submitLogin = () => {
      ruleForm.value.validate(val => {
        if (!val) return
        store.dispatch('user/getUserInfo', formLogin).then(res => {
          if (res) {
            dialogLogin.value = false
          }
        })
      })
    }
    // 封装复用 drawer
    const drawerFiles = ref(false)
    const drawerCategory = ref(false)
    const drawerArticle = ref(false)
    const drawerType = ref(null)
    const drawerOpen = type => {
      if (!store.getters.token) {
        ElMessage.error('用户未登录')
        return
      }
      if (type === 1) {
        drawerFiles.value = !drawerFiles.value
      } else if (type === 2) {
        drawerCategory.value = !drawerCategory.value
      } else if (type === 3) {
        drawerArticle.value = !drawerArticle.value
      }
      drawerType.value = type
    }
    const categoryListCom = ref([])
    const updateList = arg => {
      if (arg) categoryListCom.value = arg
      if (drawerType.value === 2) {
        getCategoryList({ include: 'tree' }).then(res => {
          if (res.data instanceof Array && res.data.length > 0) {
            res.data.unshift({ id: 0, name: '全部' })
            categoryList.value = res.data
          }
        })
      } else if (drawerType.value === 3) {
        getArticleList().then(res => {
          if (res.data.data instanceof Array && res.data.data.length > 0) {
            articleList.value = res.data.data
            articleListCopy = [...articleList.value]
          }
        })
      }
    }
    return {
      categoryList,
      articleList,
      handleSelect,
      searchVal,
      getlogin,
      dialogLogin,
      formLogin,
      rules,
      submitLogin,
      ruleForm,
      drawerFiles,
      drawerCategory,
      drawerArticle,
      drawerOpen,
      drawerType,
      updateList,
      jumpDetail,
      categoryListCom
    }
  }
})
</script>

<style scoped lang="scss">
.el-container {
  height: 100%;
  .el-header,
  .el-footer {
    padding: 0;
    height: unset;
  }
}
.el-row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-col {
    margin-bottom: 20px;
    border-radius: 4px;
    .grid-content {
      background: #8cb6c0;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      // padding-bottom: 8px;
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        font-size: 22px;
        font-weight: 700;
        padding: 0 10px;
      }
      .date {
        height: 40px;
        font-size: 16px;
        display: flex;
        justify-content: space-around;
        // padding: 0 18px;
        align-items: center;
        // &:last-child {
        //   height: 32px;
        // }
      }
    }
  }
}
.barnner {
  height: 60px;
  background-color: #434a50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .left {
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
      border-radius: 10px;
      margin-right: 30px;
      // box-shadow: 2px 2px 5px #000;
      cursor: pointer;
    }
  }
  .rigth {
    display: flex;
    align-items: center;
    .add {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      margin-right: 30px;
      border: 1px solid #fff;
      text-align: center;
      .el-icon-plus {
        color: #fff;
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
  a,
  p {
    font-size: 26px;
    font-weight: 777;
    text-decoration: none;
    color: #b6b4ae;
  }
  .el-input {
    width: 350px;
  }
}
.main {
  min-height: 100%;
  padding-bottom: 123px;
  position: relative;
  // overflow-y: auto;
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    color: #666;
    line-height: 22px;
    a {
      color: orange;
      text-decoration: none;
    }
  }
}
</style>
