<template>
  <el-menu-item v-if="!item.sub_category" :index="String(item.id)">{{
    item.name
  }}</el-menu-item>
  <el-sub-menu v-else :index="String(item.id)" @click="$emit('activeSubMenu', item.id)">
    <template #title>{{ item.name }}</template>
    <menuItem
      v-for="(val, index) in item.sub_category"
      :key="index"
      :item="val"
      v-bind="$attrs"
    />
  </el-sub-menu>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'menuItem',
  props: {
    item: {
      type: Object
    }
  }
})
</script>
