import _http from '@/utils/request'

// 获取文章列表
export function getArticleList (params) {
  return _http({
    url: '/article/list',
    params
  })
}

// 搜索文章列表
export function getArticleSearch (params) {
  return _http({
    url: '/article/search',
    params
  })
}

// 获取文章详情
export function getArticleDetail (params) {
  return _http({
    url: '/article/detail/' + params.id
  })
}

// 新建文章
export function createArticle (data) {
  return _http({
    url: '/article/create',
    method: 'post',
    data
  })
}

// 隐藏文章
export function removeArticle (params) {
  return _http({
    url: '/article/hidden/' + params.id,
    method: 'delete',
    params
  })
}

// 更新文章
export function updateArticle (data) {
  return _http({
    url: '/article/update/' + data.id,
    method: 'put',
    data
  })
}
