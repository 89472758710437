import { login } from '@/api/user'
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state () {
    return {
      token: null,
      isMobile: /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    }
  },
  mutations: {
    setToken (state, value) {
      state.token = value
    }
  },
  actions: {
    async getUserInfo ({ commit }, value) {
      const res = await login(value)
      commit('setToken', res.data.token)
      ElMessage({
        message: '登录成功',
        type: 'success'
      })
      return true
    }
  }
}
